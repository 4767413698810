import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { FieldArray } from 'react-final-form-arrays'
import { Button } from 'src/ui'
import { Label, Wrapper } from 'src/ui/form/FormField'

const CustomTagsComponent = ({ className, label, name }) => {
  const [currentTags, setTags] = useState('')

  const addValue = fields => {
    if (currentTags?.length) {
      fields.push(currentTags)
      setTags('')
    }
  }
  const handleEnter = (event, fields) => {
    if (event.key === 'Enter') {
      addValue(fields)
      event.stopPropagation()
      event.preventDefault()
      event.persist()
    }
  }

  const handleChange = event => {
    const { value } = event.target
    setTags(value)
  }

  return (
    <Wrapper className={className}>
      {label && <Label>{label}</Label>}
      <FieldArray name={name || 'subtitles'}>
        {({ fields }) => (
          <div>
            <TagsControls>
              <TagInput
                value={currentTags}
                placeholder="new subtitle text"
                type="text"
                onChange={handleChange}
                onKeyPress={event => handleEnter(event, fields)}
                maxLength={255}
              />

              <AddSubtitle
                disabled={!currentTags?.length}
                type="button"
                color="primary"
                onClick={() => addValue(fields)}
              >
                +
              </AddSubtitle>
            </TagsControls>
            <div className="subtitle-items">
              {fields?.map((subtitle, index) => {
                return (
                  <SubtitleItem key={subtitle}>
                    {fields.value[index]}
                    <Remove
                      type="button"
                      onClick={() => {
                        fields.remove(index)
                      }}
                    />
                  </SubtitleItem>
                )
              })}
            </div>
          </div>
        )}
      </FieldArray>
    </Wrapper>
  )
}

export const TagsInputField = styled(CustomTagsComponent)``

const TagInput = styled.input`
  width: 85%;
  padding: 20px;
  padding-left: 0;
  font-size: 12px;
  line-height: 12px;
  border: none;
  outline: none;
  border-bottom: 1px solid #e2ebffcf;
  border-radius: 0;
  margin-bottom: 20px;
  &:hover,
  &:focus {
    outline: none;
    border-bottom-color: #5468ff;
  }
`
export const AddSubtitle = styled(Button)`
  margin-bottom: 15px;
  min-width: 17px;
  width: 17px;
  height: 17px;
  padding: 0;
  margin-left: 10px;
  border-radius: 30px;
  color: white;
  background: #5468ff;
`
const Remove = styled(Button)`
  position: absolute;
  width: 25px;
  height: 100%;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  background: transparent;

  &:after,
  &:before {
    display: block;
    position: absolute;
    content: '';
    transform: rotate(45deg);
  }

  &:before {
    top: 14px;
    right: 8px;
    height: 1px;
    width: 11px;
    border-bottom: 1px solid white;
  }

  &:after {
    top: 9px;
    right: 13px;
    height: 11px;
    width: 1px;
    border-right: 1px solid white;
  }
`

const TagsControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SubtitleItem = styled.div`
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: 5px;

  padding: 0 28px 0 18px;
  height: 30px;

  color: white;
  font-size: 12px;
  line-height: 11px;
  background: #5468ff;
  border-radius: 3px;
`
