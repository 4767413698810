import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'
import { Form, FormSpy, Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'

import { useFloors } from 'src/modules/floors'
import { createUpload } from 'src/modules/directLoad'
import { TextField } from 'src/components/TextField'
import { ColorPicker } from 'src/components/ColorPicker'
import { ImageUploader as ImageUploaderComponent } from 'src/components/Uploader'
import { useBuildingPois } from 'src/hooks/useBuildingPois'
import { useBuildingPoiCategories } from 'src/hooks/useBuildingPoiCategories'
import { equals } from 'src/utils/arrays'
import { mapCoordToGeoJson } from 'src/utils/coordinates'
import { AccordionItem, Button, Loader, Title, BackLink } from 'src/ui'
import { useBuildingRoute } from 'src/hooks/useBuildingRoute'
import { useMap } from 'src/modules/map'
import { useAbility } from 'src/hooks/useAbility'

import { TagsInputField } from 'src/ui/form/TagInput/TagsInputField'
import { ModalDialog } from 'src/components/ModalDialog'
import { Alert } from 'src/components/Alert'
import { TextInput } from 'src/ui/form/TextInput'
import { SelectField } from 'src/components/SelectField'

import { Link } from 'src/components/Link'
import { PreviewWorkingHours } from 'src/components/WorkingHours/WorkingHours'
import { WorkingHoursModal } from 'src/components/WorkingHours/WorkingHoursModal'

import { useMapStudio } from '../controllers/MapStudioController'

function FloorsComponent() {
  const params = useParams()
  const { floorId, buildingId } = params

  const initalPolygonForm = {
    working_hours: [],
  }

  const [floorAnglesMoved, setFlorAnglesMoved] = useState(false)
  const [error, setError] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)
  const [polygonForm, setPolygonForm] = useState(initalPolygonForm)
  const [photo, setPhoto] = useState('')
  const [outline, setOutline] = useState('')
  const [color, setColor] = useState('')
  const [openEditHoursModal, setOpenEditHoursModal] = useState(false)

  const [categoriesOptions, setCategoriesOptions] = useState([
    {
      label: 'No category selected',
      value: 0,
    },
  ])

  const [showAddCategory, setShowAddCategory] = useState(false)
  const [categoryName, setCategoryName] = useState('')

  const { mapDraw } = useMap()
  const { update: updateRoute, isLoading: isSaving } = useBuildingRoute()
  const {
    get: getPoiCategories,
    create: createPoiCategories,
  } = useBuildingPoiCategories()
  const { floor, update } = useFloors()

  const {
    create: createPois,
    update: updatePoi,
    isLoading: isPoisLoading,
    getPoi,
    remove: deletePoi,
  } = useBuildingPois()

  const {
    floorPois,
    floorAnglesMarkers,
    selectedPolygon,
    idsForUpdate,
    setFloorPois,
    isLoading: isMapLoading,
    setIdsForUpdate,
    setSelectedPolygon,
    wayWasAdded,
    setWayWasAdded,
    setStopRouteDraw,
    currentRoute,
  } = useMapStudio()

  const {
    floor: { manage, update: canUpdate },
  } = useAbility()

  useEffect(() => {
    async function loading() {
      const categories = await getPoiCategories()
      if (categories?.length) {
        setCategoriesOptions(
          categoriesOptions.concat(
            categories.map(category => ({
              label: category.name,
              value: category.id,
            }))
          )
        )
      }
    }

    loading()

    return function clean() {
      setSelectedPolygon(null)
    }
  }, [])

  // fill edit form from polygon info
  useEffect(() => {
    if (selectedPolygon?.coordinates_attributes) {
      const udatedValue = {
        ...selectedPolygon,
      }

      setPolygonForm(udatedValue)

      setColor(selectedPolygon.color)
      setOutline(selectedPolygon.outline)
      if (selectedPolygon.icon !== photo) {
        setPhoto('')
      }
      setError(false)
    }
  }, [selectedPolygon, mapDraw])

  useEffect(() => {
    if (floorAnglesMarkers?.length) {
      floorAnglesMarkers.forEach(marker => {
        marker.on('dragend', () => {
          setFlorAnglesMoved(true)
        })
      })
    }
  }, [floorAnglesMarkers])

  const getPoiFromDb = useCallback(
    poiId => floorPois && floorPois?.find(item => item.poi_id === poiId),
    [floorPois]
  )

  const saveRouteFeatures = useCallback(async () => {
    setIsPageLoading(true)

    // const lines = routeGeojson.features?.find(
    //   feature => feature.geometry.type === 'MultiLineString'
    // )
    //
    // const { msNeighbors } = lines.properties

    /*   const pointsAttributes = routeGeojson.features
      ?.filter(feature => feature.geometry.type === 'Point')
      .map(feature => {
        const neighbors = []
        if (feature.properties.id !== 'MTKFUeA6EX') {
          msNeighbors.forEach(line => {
            const pointIndex = line.findIndex(
              some => some.point === feature.properties.id
            )
            // duplication check
            if (pointIndex !== -1) {
              const nPointId = line[+!pointIndex].point

              // check if current route point is already included inside the neighbors
              const selectedPointIsUnique = nPointId !== feature.properties.id

              // check if neighbor route point is exist inside the neighbors
              const pointIsUnique = neighbors.indexOf(nPointId) === -1

              if (selectedPointIsUnique && pointIsUnique) {
                neighbors.push(line[+!pointIndex].point)
              }
            }
          })
        }

        return {
          point_id: feature.properties.id,
          kind:
            feature.properties.kind ||
            feature.geometry.type.toLocaleLowerCase(),
          latitude: feature.geometry.coordinates[1],
          longitude: feature.geometry.coordinates[0],
          neighbors,
        }
      })
    */

    await updateRoute({
      building_route_points_attributes: currentRoute.virtualNeighbors,
    })

    setWayWasAdded(false)
  }, [setWayWasAdded, updateRoute, currentRoute])

  const saveDrawFeatures = useCallback(async () => {
    setIsPageLoading(true)
    const allFeatures = mapDraw.getAll().features

    for (const poiId of idsForUpdate) {
      const feature = allFeatures.find(item => item.id === poiId)
      if (
        idsForUpdate.indexOf(feature.id) !== -1 &&
        feature.geometry?.type === 'Polygon'
      ) {
        const polygonFromDB = getPoiFromDb(feature.id)

        const updatedPoi = {
          color: feature.properties.color,
          coordinates_attributes: feature.geometry.coordinates[0].map(item => ({
            longitude: item[0],
            latitude: item[1],
          })),
          latitude: feature.properties.center[1],
          longitude: feature.properties.center[0],
          outline: feature.properties.outline,
          subtitles: feature.properties.subtitles || [],
          subcategories: feature.properties.subcategories || [],
          title: feature.properties.title,
        }

        if (feature.properties.category_id) {
          updatedPoi.category_id =
            feature.properties.category_id === '0'
              ? ''
              : feature.properties.category_id
        }

        if (feature.properties.photo) {
          updatedPoi.icon = feature.properties.photo
        }

        updatedPoi.working_hours = feature.properties.working_hours

        try {
          const request = polygonFromDB
            ? await updatePoi(polygonFromDB.id, updatedPoi)
            : await createPois({
                ...updatedPoi,
                poi_id: feature.id,
                kind: 'Feature',
                geometry_type: 'Polygon',
                height: 0,
              })

          if (request?.json.error) {
            setError(
              `${request?.json.error?.message || request.statusText} (Poi_id: ${
                feature.id
              }, Title: ${updatedPoi.title})`
            )
          } else if (request.ok) {
            if (polygonFromDB) {
              const index = floorPois.findIndex(
                item => item.id === polygonFromDB.id
              )
              floorPois[index] = await getPoi(polygonFromDB.id)
            } else {
              floorPois.push(request?.json.building_poi)
            }
          }
        } catch (e) {
          setError(e)
        }
      }
    }
    setFloorPois([])
    setFloorPois(floorPois)
    setSelectedPolygon(false)
    idsForUpdate.splice(0, idsForUpdate.length)
    setIdsForUpdate([])
  }, [
    mapDraw,
    setSelectedPolygon,
    idsForUpdate,
    setIdsForUpdate,
    getPoiFromDb,
    setFloorPois,
    floorPois,
    updatePoi,
    getPoi,
    createPois,
  ])

  const onSubmit = async event => {
    setStopRouteDraw(true)
    event.stopPropagation()
    event.preventDefault()

    if (floor.coordinates && !floor.layers?.length && floorAnglesMarkers) {
      const floorCoordinates = mapCoordToGeoJson(floorAnglesMarkers)
      // compare floor coordinates
      const saveFloor = !equals(
        floor.coordinates.map(item => [+item.longitude, +item.latitude]),
        floorCoordinates.map(item => [item.longitude, item.latitude])
      )
      // save floor coordinates, if floor angles was updated
      if (saveFloor) {
        setIsPageLoading(true)
        await update(floorId, { coordinates_attributes: floorCoordinates })
      }
    }

    if (mapDraw?.getAll()?.features?.length) {
      await saveDrawFeatures()
    }

    if (wayWasAdded) {
      await saveRouteFeatures()
    }
    setIsPageLoading(false)
    setStopRouteDraw(false)
  }

  const handleUpdateDrawObjects = useCallback(
    item => {
      item.color &&
        mapDraw.setFeatureProperty(item.poi_id, 'color', item.color || color)

      mapDraw.setFeatureProperty(item.poi_id, 'subtitles', item.subtitles)
      mapDraw.setFeatureProperty(
        item.poi_id,
        'subcategories',
        item.subcategories
      )
      mapDraw.setFeatureProperty(item.poi_id, 'category_id', item.category_id)

      mapDraw.setFeatureProperty(
        item.poi_id,
        'working_hours',
        item.working_hours
      )

      item.outline &&
        mapDraw.setFeatureProperty(
          item.poi_id,
          'outline',
          item.outline || outline
        )
      if (photo || item.photo) {
        mapDraw.setFeatureProperty(item.poi_id, 'photo', photo || item.photo)
      }
      item.title && mapDraw.setFeatureProperty(item.poi_id, 'title', item.title)
      setPhoto('')
    },
    [mapDraw, photo, color, outline]
  )

  async function onDrop(file, updateProgressCallback, completeCallback) {
    setIsPageLoading(true)
    const upload = await createUpload(
      file,
      updateProgressCallback,
      completeCallback
    )
    setPhoto(upload.signed_id)
    const updatedItem = {
      ...selectedPolygon,
      icon: upload.signed_id,
      photo: upload.signed_id,
    }
    handleUpdateDrawObjects(updatedItem)
    setIsPageLoading(false)
  }

  function onRemove() {
    setPhoto('')
  }

  const handleSetColor = useCallback(
    value => {
      const updatedItem = {
        ...selectedPolygon,
        color: value,
      }
      handleUpdateDrawObjects(updatedItem)

      return setColor(value)
    },
    [selectedPolygon, handleUpdateDrawObjects]
  )
  const handleWorkingHoursChange = useCallback(
    value => {
      const workingHoursUpdated = Array.isArray(value)
        ? value.map(item => {
            let resultTimes
            if (
              Array.isArray(item.working_hours) &&
              item.working_hours[0].start
            ) {
              resultTimes = item.working_hours.map(dates => [
                dates.start,
                dates.end,
              ])
            } else {
              // eslint-disable-next-line prefer-destructuring
              resultTimes = [item.working_hours[0].replace(':', '/')]
            }

            return resultTimes
          })
        : value

      const updatedItem = {
        ...selectedPolygon,
        working_hours: workingHoursUpdated,
      }

      selectedPolygon.working_hours = workingHoursUpdated

      polygonForm.working_hours = workingHoursUpdated

      handleUpdateDrawObjects(updatedItem)
    },
    [selectedPolygon, polygonForm, handleUpdateDrawObjects]
  )

  const handleSetOutline = useCallback(
    value => {
      const updatedItem = {
        ...selectedPolygon,
        outline: value,
      }
      handleUpdateDrawObjects(updatedItem)

      return setOutline(value)
    },
    [selectedPolygon, handleUpdateDrawObjects]
  )

  function handleFormChange(form) {
    if (selectedPolygon && form?.values?.poi_id) {
      handleUpdateDrawObjects(form.values)
    }
  }

  function handleRemovePoi(event) {
    event.stopPropagation()
    event.preventDefault()
    setShowConfirmation(!showConfirmation)
  }

  function handleAddCategory(event) {
    event.stopPropagation()
    event.preventDefault()
    setShowAddCategory(!showAddCategory)
  }

  async function handleDelete() {
    const poiFromDb = getPoiFromDb(selectedPolygon.poi_id)

    if (poiFromDb) {
      await deletePoi(poiFromDb.id)
    }

    const index = floorPois.findIndex(
      item => item.poi_id === selectedPolygon.poi_id
    )

    // unselect existing poi
    setPolygonForm(initalPolygonForm)
    setSelectedPolygon(null)

    // remove poi from the main array
    floorPois.splice(index, 1)
    setFloorPois([])
    setFloorPois(floorPois)
    setShowConfirmation(!showConfirmation)

    // remove poi mask selection from map
    mapDraw.delete(selectedPolygon.poi_id)

    // remove poi id from poi for save list
    const indexUpdate = idsForUpdate.indexOf(selectedPolygon.poi_id)
    idsForUpdate.splice(indexUpdate, 1)
  }

  function handleCancel() {
    setCategoryName('')
    setShowConfirmation(false)
    setShowAddCategory(false)
  }

  function handleChangeCategory({ target }) {
    setCategoryName(target.value)
  }

  async function handleCreateCategory() {
    const category = await createPoiCategories({
      building_poi_category: { name: categoryName },
    })
    categoriesOptions.push({
      label: category.name,
      value: category.id,
    })

    setCategoriesOptions(categoriesOptions)

    polygonForm.category_id = category.id
    selectedPolygon.category_id = category.id

    handleUpdateDrawObjects(polygonForm)
    setCategoryName('')
    setShowAddCategory(false)
  }

  const validateForm = values => {
    const errors = {}

    if (!values.title) {
      errors.title = 'POI Name is Required'
    }

    return errors
  }

  const handleOpenEditHoursModal = event => {
    event.stopPropagation()
    event.preventDefault()
    setOpenEditHoursModal(true)
  }

  const handleCloseEditHoursModal = () => {
    setOpenEditHoursModal(false)
  }

  return (
    <FormContainer>
      <Title>Floor details</Title>
      <Form
        onSubmit={onSubmit}
        validate={validateForm}
        initialValues={polygonForm}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormSpy onChange={handleFormChange} />
            <AccordionItem title="details">
              <p>Floor Number: {floor.name}</p>
              <Link to={`/buildings/${buildingId}/floors/${floorId}/pois`}>
                Floor points of interest
              </Link>
              {selectedPolygon && (
                <>
                  <TextField
                    fullWidth
                    label="POI Name"
                    name="title"
                    maxLength={255}
                  />
                  <CategoryContainer>
                    <SelectCategory
                      fullWidth
                      isMulti={false}
                      isSearchable={false}
                      name="category_id"
                      options={categoriesOptions}
                      input={{
                        value: selectedPolygon.category_id,
                      }}
                      label="Category"
                    />
                    <AddNewCategory
                      disabled={!canUpdate}
                      onClick={handleAddCategory}
                    >
                      {' '}
                      +{' '}
                    </AddNewCategory>
                  </CategoryContainer>
                  <TagsInputField label="Subcategories" name="subcategories" />
                  <TagsInputField label="Subtitles" name="subtitles" />
                  <Field
                    name="working_hours"
                    workingHours={selectedPolygon.working_hours}
                    component={PreviewWorkingHours}
                    handleOpenEditHoursModal={handleOpenEditHoursModal}
                  />
                  {openEditHoursModal && (
                    <WorkingHoursModal
                      onChange={handleWorkingHoursChange}
                      workingHours={selectedPolygon.working_hours}
                      openPeriod="open24"
                      onClose={handleCloseEditHoursModal}
                    />
                  )}

                  <ImageUploader
                    photo={photo}
                    onDrop={onDrop}
                    onRemove={onRemove}
                    defaultPhoto={{ url: selectedPolygon.icon }}
                    autoWidth="true"
                  />
                  <FormField>
                    <span>Color</span>
                    <ColorPicker onChange={handleSetColor} color={color} />
                  </FormField>
                  <FormField>
                    <span>Outline</span>
                    <ColorPicker onChange={handleSetOutline} color={outline} />
                  </FormField>
                  <Actions>
                    <Remove disabled={!manage} onClick={handleRemovePoi}>
                      Delete POI
                    </Remove>
                  </Actions>
                </>
              )}
            </AccordionItem>

            <FormSpy
              subscription={{
                submitting: true,
                pristine: true,
                valid: true,
                dirtySinceLastSubmit: true,
                hasValidationErrors: true,
                submitErrors: true,
                error: true,
                errors: true,
                submitFailed: true,
              }}
            >
              {spyProps => {
                const baseError =
                  spyProps.submitErrors?.message ||
                  (selectedPolygon?.poi_id && spyProps.errors.title)
                const disableSave = !idsForUpdate.length && !wayWasAdded

                return (
                  <>
                    {(!!baseError || error) && (
                      <AlertStyled type="error" title={t(baseError || error)} />
                    )}
                    <Actions>
                      <BackLink to={`/buildings/${buildingId}`}>Back</BackLink>
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={
                          !canUpdate ||
                          ((disableSave || baseError) && !floorAnglesMoved)
                        }
                      >
                        Save
                      </Button>
                    </Actions>
                  </>
                )
              }}
            </FormSpy>
          </form>
        )}
      />
      {(isPoisLoading || isPageLoading || isSaving || isMapLoading) && (
        <Loader />
      )}
      {!!showConfirmation && (
        <ModalDialog
          title="Remove POI"
          description="This action will remove the POI, please confirm."
          actions={
            <>
              <Button mr="10px" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button color="primary" onClick={handleDelete}>
                Confirm
              </Button>
            </>
          }
        />
      )}
      {!!showAddCategory && (
        <ModalDialog
          title="Add the new category"
          description="This action will insert the new category, please enter category name and confirm."
          actions={
            <CategoryActions>
              <CategoryInput
                fullWidth
                label="Category Name"
                placeholder="Good first category"
                value={categoryName}
                onChange={handleChangeCategory}
              />
              <Actions>
                <Button mr="10px" color="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button
                  disabled={!categoryName}
                  color="primary"
                  onClick={handleCreateCategory}
                >
                  Confirm
                </Button>
              </Actions>
            </CategoryActions>
          }
        />
      )}
    </FormContainer>
  )
}

export const FloorStudio = styled(FloorsComponent)``

const Actions = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
`
const ImageUploader = styled(ImageUploaderComponent)`
  min-height: 210px;
`
const FormContainer = styled.div`
  label {
    margin: 10px 0 0 0;
  }

  select,
  input {
    padding-left: 0;
    outline: none;
    border: none;
    border-bottom: 1px solid #e2ebffcf;
    border-radius: 0;

    &:focus,
    &:hover {
      outline: none;
      border-bottom-color: #5468ff;
    }
  }
`
const FormField = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const AddNewCategory = styled(Button)`
  margin-bottom: 15px;
  width: 17px;
  height: 17px;
  padding: 0;
  margin-left: 10px;
  border-radius: 30px;
  color: white;
  background: #5468ff;
`
const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const SelectCategory = styled(SelectField)`
  width: 85%;
`
const Remove = styled(Button)`
  color: #ff6355;
`

const AlertStyled = styled(Alert)`
  color: #ff6355;
  background-color: transparent;
`

const CategoryActions = styled.div`
  display: grid;
`

const CategoryInput = styled(TextInput)``
